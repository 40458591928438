<template>
	<v-container class="pa-0" fluid>
		<!-- TOOLBAR -->
		<v-row no-gutters :style="stickyTop" class="white">
			<v-col cols="12">
				<v-container class="pa-0">
					<v-toolbar flat dense>
						<v-toolbar-title>Available to promise</v-toolbar-title>

						<template v-slot:extension>
							<v-toolbar-items>
								<v-select
									solo
									flat
									dense
									v-model="atpStatus"
									:items="atpStatusEnum"
									item-text="status"
									item-value="code"
									return-object
									hide-details
									menu-props="bottom, offset-y"
									append-icon="mdi-filter-outline"
									clearable
									placeholder="filter op status"
									@change="onSelectStatus"
								/>
							</v-toolbar-items>
							<v-spacer />
							<v-toolbar-items>
								<v-text-field
									style="max-width: 300px"
									placeholder="zoek op naam"
									v-model="search"
									append-icon="mdi-magnify"
									clearable
									hide-details="auto"
									solo
									flat
									@keyup.native.enter="onSearch"
									@click:append="onSearch"
									@click:clear="onClearSearch"
								/>
							</v-toolbar-items>
						</template>
					</v-toolbar>
				</v-container>
			</v-col>
			<v-col cols="12">
				<v-progress-linear
					v-if="loading"
					active
					indeterminate
					rounded
				></v-progress-linear>
				<v-divider v-else class="accent" style="padding-top: 2px" />
			</v-col>
		</v-row>
		<v-container>
			<v-row no-gutters>
				<v-col cols="12">
					<v-data-table
						v-show="atpList.length"
						:items="atpList"
						:headers="headers"
						item-key="RowIdent"
						hide-default-footer
						disable-pagination
						disable-sort
					>
						<template v-slot:[`item.is_visible_web`]="{ item }">
							<v-icon v-if="!item.is_visible_web" color="warning"
								>mdi-cloud-off-outline</v-icon
							>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="12" align="center">
					<v-btn
						fab
						large
						color="primary"
						v-show="loadMore"
						@click="getAtpList()"
						icon
						:loading="loading"
						><v-icon>mdi-chevron-down</v-icon></v-btn
					>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	export default {
		name: "ATP",
		data() {
			return {
				atpStatus: 0,
				atpStatusEnum: [
					{ status: "Onmiddellijk", code: "1" },
					{ status: "Op korte termijn", code: "0" },
					{ status: "Na bestelling", code: "2" },
					{ status: "Voorraadbreuk", code: "3" },
					{ status: "Eindereeks", code: "4" },
					{ status: "Uitverkocht", code: "5" },
				],
				atpList: [],
				headers: [
					{ text: "", value: "anr" },
					{ text: "", value: "zkomschr" },
					{ text: "#ATP", value: "aantal_atp" },
					{ text: "", value: "atp_omschr" },
					{ text: "", value: "is_visible_web" },
				],
				rowIdent: "",
				rowsToBatch: 50,
				loadMore: false,
				loading: false,
				search: "",
			};
		},
		computed: {
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + 0 + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			getAtpList() {
				this.loading = true;
				const sdoName = "order/objects/dArtAtp.r";
				const params = {
					action: "read",
					rowIdent: this.rowIdent,
					nbrOfRows: this.rowsToBatch,
					setOpenQuery: "for each art_atp no-lock",
				};
				// status filter
				if (this.atpStatus) {
					params.setOpenQuery +=
						" where atp_code='" + this.atpStatus.code + "'";
				}
				params.setOpenQuery += ", first artikel of art_atp ";
				if (this.search) {
					params.setOpenQuery +=
						" where artikel.zkomschr contains '" + this.search + "*'";
				}
				params.setOpenQuery += " no-lock indexed-reposition";
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							let _atpList = response.data.success.data.RowObject;
							let _nbrOfRows = response.data.success.data.nbrOfRows;
							this.atpList.push.apply(this.atpList, _atpList);
							if (_nbrOfRows === this.rowsToBatch) {
								this.loadMore = true;
								this.rowIdent = _atpList[_nbrOfRows - 1].RowIdent;
							} else {
								this.loadMore = false;
								this.rowIdent = "";
							}
						}
					})
					.finally(() => (this.loading = false));
			},
			onSelectStatus() {
				this.atpList = [];
				this.rowIdent = "";
				this.getAtpList();
			},
			onSearch() {
				this.atpList = [];
				this.rowIdent = "";
				this.getAtpList();
			},
			onClearSearch() {
				this.atpList = [];
				this.rowIdent = "";
				this.search = "";
				this.getAtpList();
			},
		},
		mounted() {
			//this.getAtpList();
		},
	};
</script>

<style>
</style>